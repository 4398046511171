<template>

    <v-form class="mt-0 pt-0" v-model="validForm">
      <div class="mt-3" align="center" v-if="sbg">
        <b>Welcome to OneFarm</b>
      </div>
      <div class="mt-3" align="center" v-if="sbg">
        <Logo />
      </div>
      <div class="mt-3" align="center" v-if="sbg">
        Please enter your OneFarm registered phone number.
      </div>
      <div>
        <v-text-field :placeholder="placeholder" v-model="identifier" :rules="identifierRules" ></v-text-field>
      </div>
      <div align="right">
        <v-btn type="submit" @click="submit" :disabled="!validForm">
          {{$t('go')}}
        </v-btn>
      </div>
    </v-form>

</template>

<script>

import domain from '@/mixins/domain';
import Identifier from '@/views/login/components/Identifier';
import Logo from '@/components/HeaderBarLogo';

export default {
  components: { Logo },
  data: () => ({
    validForm: false,
    identifier: null,
    showHelp: (self.domain=='milk'),
    placeholder: '0736527195',
    // identifierRules: email || phone number || email with partner || phone number with partner
    identifierRules: [
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || /^\d{9,}$/.test(v) || /^[\w.-@]{3,}\/\w{3,8}$/.test(v) || /^\d{9,}\/\w{3,}$/.test(v)
    ],
  }),
  computed: {
    sbg() { return ['milk'].includes(this.domain); }
  },
  methods: {
    submit() { this.$emit('input', this.identifier); },
    changePlaceholder() { this.placeholder = ( this.placeholder==='you@example.com' ? '0736527195' : 'you@example.com' ); },
  },
  mounted: function () {
    if (this.domain!='ultimate' && this.domain!='milk') {
      window.setInterval(() => { this.changePlaceholder() }, 3000);
    }
  },
  mixins: [domain],
};
</script>


<i18n>
{
  "en": {
    "go": "Go"
  },
  "es": {
    "go": "Ir"
  },
  "sw": {
    "go": "Nenda"
  }
}
</i18n>
