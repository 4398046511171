var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.approvals,"item-key":"approval"},on:{"click:row":_vm.click},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('Time',{attrs:{"time":item.created_at}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.status))])]}},{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_c('ToCustomer',{attrs:{"uuid":item.customer_uuid,"name":item.customer_name}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dense":"","color":"primary","small":""},on:{"click":function($event){return _vm.click(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple-check-outline")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }