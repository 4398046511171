<template>
  <div>
    <div class="text-caption pb-0" v-if="label">{{label}}</div>
    <v-input :rules="[requiredRule]" v-model="output" :error-messages="errorMessages" :error="errorState">

      <div class="d-flex" v-if="dateOrder=='yymmdd'">
        <SelectInput v-model="yyyy"  :items="years"  label="Year"  class="pb-0" :disabled="disabled"                     />
        <SelectInput v-model="month" :items="months" label="Month" class="pb-0" :disabled="disabled"                     />
        <SelectInput v-model="day"   :items="days"   label="Day"   class="pb-0" :disabled="disabled || days.length==0"   />
      </div>

      <div class="d-flex" v-else>
        <SelectInput v-model="day"   :items="days"   label="Day"   class="pb-0" :disabled="disabled"                     />
        <SelectInput v-model="month" :items="months" label="Month" class="pb-0" :disabled="disabled"                     />
        <SelectInput v-model="yyyy"  :items="years"  label="Year"  class="pb-0" :disabled="disabled"                     />
      </div>

    </v-input>
  </div>
</template>

<script>
  import { DateTime } from 'luxon';
  import parseDate from '@/mixins/parseDate';
  import SelectInput from '@/components/SelectInput';

  export default {

    components: { SelectInput },

    props: {
      value: [String, Date],
      required: Boolean,
      rules: Array,
      label: String,
      min: String,
      max: String,
      year: Boolean,
      disabled: Boolean,
      hint: String,
      singleYear: Number,
      past: Boolean,
      future: Boolean,
    },

    data: () => ({
      date: undefined,
      yyyy: undefined,
      month: undefined,
      day: undefined,

      result: undefined,

      errorMessages: [],

      allMonths: [
        { value:  1, text: 'Jan' },
        { value:  2, text: 'Feb' },
        { value:  3, text: 'Mar' },
        { value:  4, text: 'Apr' },
        { value:  5, text: 'May' },
        { value:  6, text: 'Jun' },
        { value:  7, text: 'Jul' },
        { value:  8, text: 'Aug' },
        { value:  9, text: 'Sep' },
        { value: 10, text: 'Oct' },
        { value: 11, text: 'Nov' },
        { value: 12, text: 'Dec' },
      ],
    }),

    computed: {

      debugMax() { return (this.maxDate ?  this.maxDate.toLocaleString(DateTime.DATETIME_MED) : undefined); },
      debugMin() { return (this.minDate ?  this.minDate.toLocaleString(DateTime.DATETIME_MED) : undefined); },
      debugDate() { return (this.date   ?  this.date.toLocaleString(DateTime.DATETIME_MED) : undefined); },

      output() {
        let r = undefined;
        if (this.yyyy && this.month && this.day) {
          if (this.days.includes(this.day)) {
            r = `${this.yyyy}-${String(this.month).padStart(2,'0')}-${String(this.day).padStart(2,'0')}`;
          }
        }
        return r;
      },
      errorState() { return (this.errorMessages.count>0); },
      allRules() { return (!!this.rules ? [this.requiredRule, ...this.rules] : [this.requiredRule]); },
      countryCode() { return this.$store.getters.settings.countryCode; },
      maxDate() {
        let r = null;
        if (this.max) { r = this.getDate(this.max); }
        if (this.past) {
          if ( !(r && r > DateTime.now()) ) { r = DateTime.now(); }
        }
        return r;
      },
      minDate() {
        let r = null;
        if (this.min) { r = this.getDate(this.min); }
        if (this.future) {
          if ( !(r && r > DateTime.now()) ) { r = DateTime.now(); }
        }
        return r;
      },
      years() {
        let y = [...Array(120).keys()].map( e => (new Date).getFullYear()-e+50 );
        return y.filter( e => e>=( (this.minDate ? this.minDate.year : null) || 1900) && e<=( (this.maxDate ? this.maxDate.year : null) || 2040) && e==(this.singleYear || e) );
      },
      months() {
        let r = this.allMonths;
        if ((this.day || 0)==31) { r = r.filter(  e => [1, 3, 5, 7, 8, 10, 12].includes(e.value) ) }
        if ((this.day || 0)==30) { r = r.filter(  e => e.value!==2 ) }
        return r;
      },
      days() {
        let r = [];
        if ([9, 4, 6, 11].includes(this.month)) {
          r = Array.from({length: 30}, (_, i) => i + 1)
        } else if ([2].includes(this.month)) {
          r = Array.from({length: 29}, (_, i) => i + 1)
        } else {
          r = Array.from({length: 31}, (_, i) => i + 1)
        }
        return r;
      },
      dateOrder() {
        let r = 'ddmmyy';
        if (this.countryCode=='UG') { r = 'yymmdd' };
        return r;
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.date = this.getDate(to); },
      },
      date(to) {
        if (to) {
          let t = this.getDate(to);
          this.yyyy = t.year;
          this.month = t.month;
          this.day = t.day;
          this.errorMessages = [];
          if (this.maxDate && this.maxDate<t) {
            this.errorMessages = ['Too far into the future'];
          }
          if (this.minDate && this.minDate>t) {
            this.errorMessages = ['Too far into the past'];
          }
        }
      },
      output(to) {
        this.$emit('input', to);
      },
      years(to) {
        if ((to || []).length==1) {
          this.yyyy = to[0];
        }
      }
    },

    methods: {
      requiredRule(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
      getDate(t) { return this.parseDateTime(t); },
    },
    mixins: [parseDate],
  };
</script>


<i18n>
{
  "en": {
    "year": "Year",
    "month": "Month",
    "day": "Day"
  },
  "es": {
    "year": "Año",
    "month": "Mes",
    "day": "Día"
  },
  "sw": {
    "year": "Mwaka",
    "month": "Mwezi",
    "day": "Siku"
  }
}
</i18n>

