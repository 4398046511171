<template>
  <div class="text-left">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-h4">
          <p class="subtitle-1">Select input options </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="text-h4">

          <p class="subtitle-2">Multiselect with close</p>
          <v-select
              v-model="selectedFruits"
              :items="fruits"
              label="Favorite Fruits"
              multiple
              ref="customSelect"
          >
            <template v-slot:prepend-item>
              <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggle"
              >
                <v-list-item-action>
                  <v-icon :color="selectedFruits.length > 0 ? 'indigo darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-btn block @click="$refs.customSelect.isMenuActive = false">
                    Close
                  </v-btn>
                </v-list-item-content>

              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
export default {
  data: () => ({
    fruits: [
      'Apples',
      'Apricots',
      'Avocado',
      'Bananas',
      'Blueberries',
      'Blackberries',
      'Boysenberries',
      'Bread fruit',
      'Cantaloupes (cantalope)',
      'Cherries',
      'Cranberries',
      'Cucumbers',
      'Currants',
      'Dates',
      'Eggplant',
      'Figs',
      'Grapes',
      'Grapefruit',
      'Guava',
      'Honeydew melons',
      'Huckleberries',
      'Kiwis',
      'Kumquat',
      'Lemons',
      'Limes',
      'Mangos',
      'Mulberries',
      'Muskmelon',
      'Nectarines',
      'Olives',
      'Oranges',
      'Papaya',
      'Peaches',
      'Pears',
      'Persimmon',
      'Pineapple',
      'Plums',
      'Pomegranate',
      'Raspberries',
      'Rose Apple',
      'Starfruit',
      'Strawberries',
      'Tangerines',
      'Tomatoes',
      'Watermelons',
      'Zucchini',
    ],
    selectedFruits: [],
  }),
  computed: {
    likesAllFruit () {
      return this.selectedFruits.length === this.fruits.length
    },
    likesSomeFruit () {
      return this.selectedFruits.length > 0 && !this.likesAllFruit
    },
    icon () {
      if (this.likesAllFruit) return 'mdi-close-box'
      if (this.likesSomeFruit) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedFruits = []
        } else {
          this.selectedFruits = this.fruits.slice()
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
