<template>

  <v-container fluid class="pa-0">

    <v-row>

      <v-col :cols="cols" v-if="selectable">
        <v-select
          v-model="identityType"
          :items="identityTypeList"
          :label="$t('identityType.label')"
          no-data-text="Select an Identity Type"
          :rules="[requiredRule]"
        ></v-select>
      </v-col>

      <v-col :cols="cols">
        <v-text-field
          v-model="identityNumber"
          :rules="[identityNumberRules]"
          :counter="identityLength"
          :label="$t(idNumberLabel)"
          :disabled="disableIdentityNumber"
        ></v-text-field>
      </v-col>

      <v-col :cols="cols" v-if="showCardNumber">
        <v-text-field
          v-model="cardNumber"
          :rules="[cardNumberRules]"
          :counter="9"
          label="Card Number"
          :disabled="disableIdentityNumber"
        ></v-text-field>
      </v-col>

    </v-row>

  </v-container>

</template>

<script>
  import i18nUtils from '@/mixins/i18n';
  export default {
    props: {
      value: Object,
      required: Boolean,
      country: String,
      defaultType: String,
      none: {
        type: Boolean,
        default: true,
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      idTypes: Array,
    },
    data: () => ({
      identityType: undefined,
      identityNumber: undefined,
      cardNumber: undefined,
    }),
    computed: {
      countryCode() { return this.country || this.$store.getters.settings.countryCode; },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      disableIdentityNumber() { return ((this.identityType || '')=='' || (this.identityType || '')=='none') },
      idNumberLabel() { return ( this.countryCode=='TZ' ? 'NIDA Number' : 'ID Number' ); },
      result() { return { type: this.identityType, number: this.identityNumber, cardNumber: this.cardNumber }; },
      identityTypeIds() {
        let r = ['none', 'voters_card', 'national_id', 'drivers_license', 'passport'];
        if (this.countryCode==='CO') { r = ['none', 'national_id', 'foreigner_id', 'passport']; }
        if (this.countryCode==='ZA') { r = ['none', 'national_id', 'passport']; }
        if (!this.none) { r = r.filter( e => e!=='none' ); }
        if (this.idTypes) { r = r.filter( e => this.idTypes.includes(e) ); }
        return r;
      },
      identityTypeList() {
        return this.i18nArray(this.$t('identityType.items')).filter(e => this.identityTypeIds.includes(e.value));
      },
      showCardNumber() { return (this.countryCode=='UG' && this.identityType=='national_id'); },
      identityLength() {
        let r = 20;
        if (this.identityType=='national_id') {
          if (this.countryCode==='BW') { r = 9; }
          if (this.countryCode==='KE') { r = 8; }
          if (this.countryCode==='TZ') { r = 20; }
          if (this.countryCode==='UG') { r = 14; }
          if (this.countryCode==='ZA') { r = 13; }
        }
        return r;
      },
      cols() {
        let c = undefined;
        if (this.$vuetify.breakpoint.name=='xs') {
          c = 12;
        } else {
          if (this.selectable) {
            if (this.showCardNumber) {
              c = 4;
            } else {
              c = 6;
            }
          } else {
            if (this.showCardNumber) {
              c = 6;
            } else {
              c = 12;
            }
          }
        }
        return c;
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.identityType = to['type'];
            this.identityNumber = to['number'];
            this.cardNumber = to['cardNumber'];
          } else {
            this.identityType = undefined;
            this.identityNumber = undefined;
            this.cardNumber = undefined;
          }
        }
      },
      defaultType: {
        immediate: true,
        handler(to) {
          if (to) {
            this.identityType = this.identityType || this.defaultType;
          }
        }
      },
      result(to) {
        let identity = {
          type: this.identityType,
          number: (this.identityNumber || '').toUpperCase(),
          cardNumber: (this.cardNumber),
        }
        if (this.identityType=='national_id') {
          const id = (this.identityNumber || '');
          if ( (this.countryCode==='TZ') && (this.validTzId(id)) ) {
            identity.birthdate = new Date(id.substring(0, 4), id.substring(4, 6) - 1, id.substring(6, 8), 12);
            const gender = parseInt(id.substring(18, 19));
            if (gender==1 || gender==2) {
              identity.gender = (gender==1 ? "female" : "male");
            }
          }
          
          if ( (this.countryCode==='UG') && (this.validUgId(id)) ) {
            identity.gender = (id.substring(1, 2)=='M' ? "male" : "female");
            let idYear = parseInt(id.substring(2,4));
            let currentYear = parseInt(new Date().getFullYear().toString().substr(-2));
            identity.yearOfBirth = ( (idYear<currentYear) ? 2000+idYear : 1900+idYear );
          }
          if ( (this.countryCode==='ZA') && (this.validZaId(id)) ) {
            let birthdate = new Date(id.substring(0, 2), id.substring(2, 4) - 1, id.substring(4, 6));
            if (birthdate.getFullYear() < ((new Date().getFullYear())-100) ) {
              birthdate = new Date(birthdate.getFullYear() + 100, birthdate.getMonth(), birthdate.getDate(), 12);
            }
            identity.birthdate = birthdate.toISOString().substring(0,10);
            identity.gender = (parseInt(id.substring(6, 10)) < 5000 ? "female" : "male");
          }
        }
        this.$emit('input', identity );
      },
    },
    methods: {
      requiredRule(v) {
        return !this.required || !!((v || '').trim()) || this.$t('required');
      },
      cardNumberRules(v) {
        let r = true;
        if (this.countryCode==='UG' && this.identityType=='national_id') {
          r = this.validUgCard(v || '');
        }
        return (r || 'Invalid Card Number');
      },
      identityNumberRules(v) {
        let r = false;
        let value = v || '';
        if (this.partnerCode == 'urban_zm'){
          r = true
        }
        else if (this.identityType==='national_id') {
          if (this.countryCode==='BW') {
            r = this.validBwId(value) || 'Invalid BW ID Number';
          } else if (this.countryCode==='KE') {
            r = this.validKeId(value) || 'Invalid KE ID Number';
          } else if (this.countryCode==='TZ') {
            r = this.validTzId(value) || 'Invalid TZ ID Number';
          } else if (this.countryCode==='UG') {
            r = this.validUgId(value) || 'Invalid UG ID Number';
          } else if (this.countryCode==='ZA') {
            r = this.validZaId(value) || 'Invalid ZA ID Number';
          } else {
            r = (this.identityType=='') || (this.identityType=='none') || /^.{4,20}$/.test(value) || this.$t('errorMessage');
          }
        } else {
          r = (this.identityType=='') || (this.identityType=='none') || /^.{4,20}$/.test(value) || this.$t('errorMessage');
        }
        return r;
      },
      validBwId(id) {
        let r = true;
        if (r) { r = (id.length==9) }
        if (r) { r = !!(id.match(/^[0-9]{9}$/)) }
        return r;
      },
      validKeId(id) {
        let r = true;
        if (r) { r = (id.length==8) }
        if (r) { r = !!(id.match(/^[0-9]{8}$/)) }
        return r;
      },
      validTzId(id) {
        let r = true;
        if (r) { r = (id.length==20) }
        if (r) { r = !!(id.match(/^\d{20}$/)) }
        if (r) { r = !!( id.substring(0,8).match(/^(?:19\d{2}|20[012][0-9])(?:0[1-9]|1[012])(?:0[1-9]|[12][0-9]|3[01])$/) ) }
        return r;
      },
      validUgId(id) {
        // https://ssmusoke.com/2018/02/26/crowdsourcing-validation-rules-for-uganda-national-id/
        let r = true;
        id = id.toUpperCase();
        if (r) { r = (id.length==14) }
        if (r) { r = !!(id.match(/^[A-Z][MF]\d{5}[0-9A-Z]{7}$/)); }
        return (r ? true : false);
      },
      validUgCard(id) {
        let r = true;
        if (r) { r = (id.length==9 || id.length==10) }
        if (r) { r = !!(id.match(/^\d{9,10}$/)); }
        return (r ? true : false);
      },
      validZaId(id) {
        let i, c,
            even = '',
            sum = 0,
            check = id.slice(-1);
        if (id.length != 13 || id.match(/\D/)) {
            return false;
        }
        id = id.substr(0, id.length - 1);
        for (i = 0; c = id.charAt(i); i += 2) {
          sum += +c;
          even += id.charAt(i + 1);
        }
        even = '' + even * 2;
        for (i = 0; c = even.charAt(i); i++) {
          sum += +c;
        }
        sum = 10 - ('' + sum).charAt(1);
        return ('' + sum).slice(-1) == check;
      }
    },
    mixins: [i18nUtils],
  };
</script>



<i18n>
{
  "en": {
    "identityNumber": "ID Number",
    "errorMessage": "Must be between 4 and 20 characters",
    "identityType": {
      "label": "Identity Document",
      "items": [
        { "label": "none",             "value": "None Provided"     },
        { "label": "voters_card",      "value": "Voters Card"       },
        { "label": "national_id",      "value": "National ID"       },
        { "label": "drivers_license",  "value": "Drivers License"   },
        { "label": "passport",         "value": "Passport"          },
        { "label": "foreigner_id",     "value": "Foreigner ID"      }
      ]
    }
  },
  "es": {
    "identityNumber": "Numero de Identidad",
    "errorMessage": "Debe tener entre 4 y 20 caracteres",
    "identityType": {
      "label": "Documento de Identidad",
      "items": [
        { "label": "none",             "value": "Ninguno proporcionado"     },
        { "label": "voters_card",      "value": "Tarjeta de votantes"       },
        { "label": "national_id",      "value": "Cédula de Ciudadanía"      },
        { "label": "drivers_license",  "value": "Licencia de conducir"      },
        { "label": "passport",         "value": "Pasaporte"                 },
        { "label": "foreigner_id",     "value": "Cédula de Extranjería"     }
      ]
    }
  },
  "sw": {
    "identityNumber": "Namba ya Kitambulisho",
    "errorMessage": "Lazima iwe kati ya herufi 4 na 20",
    "identityType": {
      "label": "Aina ya Kitambulisho",
      "items": [
        { "label": "none",             "value": "Hakuna Iliyotolewa"     },
        { "label": "voters_card",      "value": "Kadi ya Mpiga kura"     },
        { "label": "national_id",      "value": "Kitambulisho cha Taifa" },
        { "label": "drivers_license",  "value": "Leseni ya Udereva"      },
        { "label": "passport",         "value": "Pasipoti"               },
        { "label": "foreigner_id",     "value": "Kitambulisho cha Mgeni" }
      ]
    }
  }
}

</i18n>