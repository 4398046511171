var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.formFields)?_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-select',{attrs:{"items":_vm.productCategories,"label":"Category","dense":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"w-100",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.tableResults,"item-key":"item.uuid"},scopedSlots:_vm._u([{key:"header.description",fn:function(){return [_vm._v(_vm._s(_vm.$t("Description")))]},proxy:true},{key:"header.category",fn:function(){return [_vm._v(_vm._s(_vm.$t("Category")))]},proxy:true},{key:"header.price_per_unit",fn:function(){return [_vm._v(_vm._s(_vm.$t("Price"))+" "),_c('small',[_vm._v("/ "+_vm._s(_vm.$t("unit")))])]},proxy:true},{key:"header.quantity",fn:function(){return [_vm._v(_vm._s(_vm.$t("Quantity")))]},proxy:true},{key:"header.cost",fn:function(){return [_vm._v(_vm._s(_vm.$t("Cost")))]},proxy:true},{key:"item.price_per_unit",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":item.price_per_unit}}),_c('span',{staticClass:"hidden-xs-only pl-0"},[_c('small',[_vm._v("/ "+_vm._s(item.unit))])])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.category ? item.category : "Generic"))])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticStyle:{"text-align":"right"},attrs:{"min":"0","type":"number","rules":(item.unit === 'l' ? _vm.numberWithDecimalRules : _vm.numberRules)},on:{"input":function($event){return _vm.updateCost(item.uuid)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":Math.round(parseFloat(item.quantity) * item.price_per_unit)}})]}}],null,false,3585013186)})],1),_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12 d-flex justify-space-between"}},[_c('div'),_c('div',[_vm._v(" Total "),_c('Currency',{attrs:{"value":_vm.cost}})],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }